import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { SetupService } from 'src/app/services/setup.service';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/model/user.interface';
import { Router } from '@angular/router';
import { CheckDeviceService } from 'src/app/services/check-device.service';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [NgIf, TranslateModule, NgClass],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

  setupService = inject(SetupService);

  constructor(
    private userService: UserService,
    private router: Router,
    private checkDevice: CheckDeviceService
  ) { }

  get user(): User {
    return this.userService.user;
  }

  goToMartekplace(): void {
    this.router.navigate(['/marketplace/staffing-and-recruitment'], {queryParams: {companyId: this.setupService.companyId}});
  }

  get isMobile(): boolean {
    return this.checkDevice.isMobile();
  }
}
