<header class="header-container" [ngClass]="{'header-auth': !!user}">
  <ng-container *ngIf="!isMobile">
    <div class="marketplace-wrapper" *ngIf="setupService.showMarketplaceBanner()">
        <p>{{ 'STAFFING_AND_RECRUITMENT.BANNER_PAR_1' | translate }}</p>
        <p>{{ 'STAFFING_AND_RECRUITMENT.BANNER_PAR_2' | translate }}</p>
      <button (click)="goToMartekplace()">{{ 'BUTTONS.CLICK_HERE' | translate }}</button>
     </div>
  </ng-container>
  <img [src]="setupService.logo()"
  class="header-logo img-fluid" />
</header>
